import { FetchMediaError } from 'fetch-media';
import { useMutation, useQueryClient } from 'react-query';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';
import { topUp } from '@introcloud/api-client';
import { createURL } from 'expo-linking';
import { Linking, Platform } from 'react-native';

export function useTopUp() {
  const queryClient = useQueryClient();
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();

  return useMutation<
    unknown,
    FetchMediaError,
    {
      amount: number;
      paymentMethod: string;
      bankId?: string | undefined;
    }
  >(
    ['app', 'payment', 'top-up'],
    async ({ amount, paymentMethod, bankId }) => {
      if (!authorization) {
        throw new Error('Expected to be authorized');
      }

      await queryClient.cancelQueries([authorization, 'app', 'balance']);

      const result = await topUp(endpoint, authorization!, {
        methodId: paymentMethod,
        bankId,
        cents: amount,
        returnUrl: createURL('/payment'),
      });

      if (result.ok) {
        if (Platform.OS === 'web') {
          const a = document.createElement('a');
          a.href = result.url;
          a.click();
        } else {
          return Linking.openURL(result.url);
        }
      }
    },
    {
      onSettled: async () => {
        return queryClient.invalidateQueries([authorization, 'app', 'balance']);
      },
    }
  );
}
