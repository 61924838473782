import { TactileEvent } from '@introcloud/api-client';
import { useBlockDataEvent } from '@introcloud/blocks-interface';
import { PageData } from '@introcloud/page';
import { TabParamList } from '@introcloud/tabs';
import { useRoute } from '@react-navigation/native';
import { v4 as generateUUID } from 'uuid';
import React, { useMemo } from 'react';
import { BlockProvision } from '../core/BlockProvision';
import { EVENT_CACHE } from '../core/Cache';
import { RouteProp } from '../core/Routes';
import { useCompanyTabs } from '../hooks/useCompanyTabs';
import { useGroups } from '../hooks/useGroup';
import { useUser } from '../hooks/useUser';
import { LoadingPage, Page, PageWithContext } from '../page/Page';

export function EventScreen() {
  const route = useRoute<RouteProp<'Event'>>();
  const event = route.params?.id;

  return (
    <BlockProvision screen="EventScreen">
      <EventPage id={event} />
    </BlockProvision>
  );
}

function EventPage({ id }: { id: string }) {
  const { data: event } = useBlockDataEvent(id);
  const { values } = useCompanyTabs();
  const fallBack = useMemo(
    () => ({
      screen: 'Tabs' as const,
      params: {
        screen: values.some((tab) => tab.tab === 'event-days')
          ? 'EventDays'
          : values.some((tab) => tab.tab === 'events')
          ? 'Events'
          : values.some((tab) => tab.tab === 'home')
          ? 'Home'
          : ((values[0].tab[0].toLocaleUpperCase() +
              values[0].tab.slice(1)) as keyof TabParamList),
      },
    }),
    [values]
  );

  const actualEvent = event || EVENT_CACHE.current[id];

  if (!actualEvent) {
    // TODO loading/error
    return <LoadingPage fallBack={fallBack} />;
  }

  if (actualEvent && actualEvent.pageRef && actualEvent.pageRef.pageId) {
    return <Page page={actualEvent.pageRef.pageId} fallBack={fallBack} />;
  }

  return <DefaultEventPage event={actualEvent} fallBack={fallBack} />;
}

function DefaultEventPage({
  event,
  fallBack,
}: {
  event: TactileEvent;
  fallBack: any;
}) {
  const { data: currentUser } = useUser();
  const { data: currentGroups } = useGroups();

  const page = useMemo((): PageData => {
    return {
      page: {
        _id: event._id,
        _v: -1,
        module: { application: { colors: { primary: '', accent: '' } } },
        name: { ...event.name, description: event.name.description || '' },
        nameLocalized: event.nameLocalized,
        subTitle: '',
        eventRef: [{ eventId: event._id }],
        locationRef: [],
        content: [
          {
            _id: generateUUID(),
            kind: 'auto',
            value: {
              auto: 'image',
            },
            options: {
              mode: 'cover',
              ratio: [16, 9],
            },
          },
          {
            _id: generateUUID(),
            kind: 'auto',
            value: {
              auto: 'title',
            },
            options: {},
          },
          {
            _id: generateUUID(),
            kind: 'auto',
            value: {
              auto: 'description',
            },
            options: {},
          },
          {
            _id: generateUUID(),
            kind: 'auto',
            value: {
              auto: 'timespan',
            },
            options: {
              mode: 'duration',
            },
          },
          {
            _id: generateUUID(),
            kind: 'auto',
            value: {
              auto: 'sub-events',
            },
            options: {},
          },
          {
            _id: generateUUID(),
            kind: 'spacer',
            value: {},
            options: {
              space: 1,
            },
          },
          {
            _id: generateUUID(),
            kind: 'auto',
            value: {
              auto: 'locations',
            },
            options: {},
          },
        ],
      },
      pageEvent: event,
      pageLocation: null,
      currentUser: currentUser || null,
      currentGroups: currentGroups || null,
    };
  }, [event._id, currentUser]);
  return (
    <BlockProvision screen="DefaultEventPage (EventScreen)" page={page}>
      <PageWithContext fallBack={fallBack} />
    </BlockProvision>
  );
}
