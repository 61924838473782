import { fetchApplication, TactileCompany } from '@introcloud/api-client';
import { CURRENT_LOCALE } from '@introcloud/blocks';
import dlv from 'dlv';
import { fetchMedia, FetchMediaError, JsonError } from 'fetch-media';
import React, {
  createContext,
  createElement,
  useContext,
  useDebugValue,
  useMemo,
} from 'react';
import { UseMutateAsyncFunction, useMutation } from 'react-query';
import { INTROCLOUD_ENDPOINT } from '../config';
import {
  login,
  logout,
  Permit,
  resolve,
  subscribe,
  usePermits,
} from '../core/Authentication';
import { queryClient } from '../core/QueryCache';
import { MULTI_COMPANY_ENABLED } from '../features';
import { resetLocale } from '../localize';
import { AnyValue, NoValue, Serializable, UndeterminedValue } from '../storage';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { COMPANY } from './useCompany';

export type { Permit };

export interface SelectCompany {
  domain: string;
  domainFull: string | undefined;
  name: {
    full: string;
  };
}
interface PermitRequest {
  email: string;
  password: string;
}

type AuthenticationContextType = {
  authentication: AnyValue<Permit | null>;
  available: readonly string[];
  resolve(key: string): Promise<Permit | null>;
  login(next: Permit): Promise<unknown>;
  logout(): Promise<unknown>;
  remove(key: string): Promise<unknown>;
  noContext?: boolean;
  noPermits?: boolean;
};

const AuthenticationContext = createContext<AuthenticationContextType>({
  authentication: undefined,
  available: [],
  resolve() {
    return Promise.reject(new Error('not ready'));
  },
  login() {
    return Promise.reject(new Error('not ready'));
  },
  logout() {
    return Promise.reject(new Error('not ready'));
  },
  remove() {
    return Promise.reject(new Error('not ready'));
  },
  noContext: true,
});

const ACCEPT = 'application/json';
const CONTENT_TYPE = 'application/json; charset=utf-8';

function assert<T extends Serializable>(
  value: AnyValue<T> | NoValue
): NonNullable<T> {
  if (__DEV__ && !value) {
    throw new Error(
      `The value passed in assert should have been set, actual ${typeof value}.
      You should guard for this higher up.

      When using the authentication hooks, check if the user is authenticated
      using useIsAuthenticated(). If this returns false, it is NOT safe to use
      useAuthorization().`
    );
  }

  return value!;
}

export interface AuthenticateEmail {
  attempt: UseMutateAsyncFunction<
    AuthenticateResult,
    Error | FetchMediaError,
    AuthenticateArgs,
    unknown
  >;

  reset(): void;

  isLoading: boolean;
  error: FetchMediaError | Error | null;
}

type AuthenticateArgs = {
  username: string;
  password: string;
  option?: SelectCompany;
};

export interface Validate {
  validate: UseMutateAsyncFunction<
    SelectCompany[],
    Error | FetchMediaError,
    string,
    unknown
  >;

  reset(): void;

  options: SelectCompany[] | undefined;
  isLoading: boolean;
  error: FetchMediaError | Error | null;
}

export function useAuthentication() {
  return useContext(AuthenticationContext);
}

function useProvideAuthentication(): AuthenticationContextType {
  const { current: permits, remove } = usePermits();

  return useMemo(() => {
    if (permits === undefined) {
      return {
        authentication: undefined,
        available: [],
        resolve,
        login,
        logout,
        remove,
        noPermits: true,
      };
    }

    return {
      authentication:
        permits?.permits[permits.lastPermit || '']?.current ?? null,
      available: Object.keys(permits?.permits || {}),
      resolve,
      login,
      logout,
      remove,
    };
  }, [permits?.lastPermit, permits?.hydrated, remove]);
}

export function AuthenticationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const value = useProvideAuthentication();
  // console.log({ value });
  return createElement(AuthenticationContext.Provider, { value }, children);
}

type AuthenticateResult = {
  company?: TactileCompany;
  nextDomain: string;
  expiresAt: number;
  token: string;
};

type ValidatedResponse = {
  message: string;
  data: {
    company: {
      correct: false;
      select: SelectCompany[];
    };
  };
};

export function AuthenticationPortal({
  permit,
  children,
}: {
  permit: Permit;
  children: React.ReactNode;
}) {
  const parent = useContext(AuthenticationContext);
  const value = useMemo(
    () => ({ ...parent, authentication: permit }),
    [permit, parent]
  );

  return createElement(AuthenticationContext.Provider, { value }, children);
}

export function useValidateEmail(overrideDomain?: string): Validate {
  const { mutateAsync, error, isLoading, data, reset } = useMutation<
    SelectCompany[],
    FetchMediaError | Error,
    string
  >(['auth', 'user', 'validate'], async (username: string) => {
    if (!username) {
      throw new Error('Enter your email address');
    }

    const body = {
      email: username,
    };

    const endpoint = overrideDomain
      ? `https://${overrideDomain}`
      : INTROCLOUD_ENDPOINT;
    const url = [endpoint, 'api', 'public', 'auth', 'email-validate'].join('/');

    const response = await fetchMedia(url, {
      headers: {
        accept: ACCEPT,
        contentType: CONTENT_TYPE,
      },
      method: 'POST',
      body,
      disableFormData: true,
      disableFormUrlEncoded: true,
      disableText: true,
    })
      .catch((error) => {
        if (error instanceof FetchMediaError) {
          if (error.response.status === 300) {
            return (error as JsonError).data;
          }
        }

        return Promise.reject(error);
      })
      .then((response) => response as ValidatedResponse);
    const options = response.data?.company?.select || [];

    if (options.length === 0 && !overrideDomain) {
      return Promise.reject(
        new Error(
          "It looks like you're registered to at least one Tactile event, but not this one."
        )
      );
    }

    return options;
  });

  return { validate: mutateAsync, error, isLoading, options: data, reset };
}

export function useAuthenticateEmail(
  specificDomain?: string
): AuthenticateEmail {
  const { mutateAsync, error, isLoading, reset } = useMutation<
    AuthenticateResult,
    FetchMediaError | Error,
    AuthenticateArgs
  >(
    ['auth', 'user', 'email'],
    async ({ username, password, option }) => {
      if (!username) {
        throw new Error('Enter your email address');
      }

      if (!password) {
        throw new Error('Enter your password');
      }

      const endpoint = specificDomain
        ? `https://${specificDomain}`
        : option && option.domainFull
        ? option.domainFull
        : INTROCLOUD_ENDPOINT;

      const url = [endpoint, 'api', 'public', 'auth', 'login'].join('/');

      const body: PermitRequest = {
        email: username,
        password,
      };

      const { data, message } = (await fetchMedia(url, {
        headers: {
          accept: ACCEPT,
          contentType: CONTENT_TYPE,
        },
        method: 'POST',
        body,
        disableFormData: true,
        disableFormUrlEncoded: true,
        disableText: true,
      })) as any;

      const hasValidUser = dlv(data, ['email', 'correct'], false);

      if (!hasValidUser || dlv(data, ['password', 'correct']) === false) {
        throw new Error('E-mail or password incorrect');
      }

      const {
        token: { value: token, expires: expiresAt },
      } = data;

      // Need this guard because the API will return a success code even when it's not ok.
      if (!token) {
        throw new Error(
          message || 'Something went wrong; please contact us or try again.'
        );
      }

      // Analytics.logEvent('login', { method: 'email' });

      const nextDomain = specificDomain
        ? `https://${specificDomain}`
        : option?.domainFull || INTROCLOUD_ENDPOINT;

      const company = MULTI_COMPANY_ENABLED
        ? await fetchApplication(
            nextDomain + '/api',
            undefined,
            SHOULD_DEBUG_FETCH
          )
        : undefined;

      return { company, nextDomain, token, expiresAt };
    },
    {
      onSuccess: async ({ company, nextDomain, token, expiresAt }) => {
        if (company) {
          await COMPANY.emit(company);

          if (MULTI_COMPANY_ENABLED) {
            queryClient.setQueryData(
              ['api', 'company', 'https://api.tactile.events'],
              company
            );
          }

          await queryClient.invalidateQueries(['api', 'company', nextDomain]);
        }

        await login({
          domainFull: nextDomain,
          token,
          expiresAt,
        });
      },
    }
  );

  return { attempt: mutateAsync, error, isLoading, reset };
}

export function useLogout() {
  return logout;
}

export function imperativeLogout() {
  return logout();
}

export function useCurrentDomain() {
  const { authentication } = useAuthentication();
  const authenticatedDomain = authentication?.domainFull;

  useDebugValue(authenticatedDomain);

  return MULTI_COMPANY_ENABLED
    ? authenticatedDomain || INTROCLOUD_ENDPOINT
    : INTROCLOUD_ENDPOINT;
}

export function useEndpoint() {
  const authenticatedDomain = useCurrentDomain();

  return `${authenticatedDomain}/api`;
}

export function useIsAuthenticated(): boolean | UndeterminedValue {
  const { authentication } = useAuthentication();

  if (authentication === undefined) {
    return undefined;
  }

  return !!authentication;
}

export function useAuthorization(): string {
  return assert(useSafeAuthorization());
}

export function useSafeAuthorization(): string | null | undefined {
  const { authentication } = useAuthentication();
  return authentication ? `${authentication.token || ''}` : authentication; // Token token=${authentication.token}
}

export function runOnLogout(listener: () => void) {
  const onLogout = (next: AnyValue<Permit | null>) => {
    if (next === null) {
      listener();
    }
  };

  return subscribe(onLogout);
}

if (typeof window !== 'undefined' && 'addEventListener' in window) {
  const key = 'token';

  const onSettingsMessage = (ev: MessageEvent) => {
    if (typeof ev.data !== 'object' || ev.data === null) {
      return;
    }

    if (ev.data.type !== key) {
      return;
    }

    const {
      token,
      domain = INTROCLOUD_ENDPOINT,
      expires = new Date().getTime() + 1000 * 60 * 60,
    } = typeof ev.data.value === 'string'
      ? { token: ev.data.value }
      : ev.data.value;

    const endpoint = [
      MULTI_COMPANY_ENABLED ? INTROCLOUD_ENDPOINT : domain,
      'api',
    ].join('/');

    // Allow other domains
    fetchApplication(endpoint, undefined, SHOULD_DEBUG_FETCH)
      .then(async (company) => {
        queryClient.setQueryData(['api', 'company', endpoint], company);
        queryClient.setQueryData(['api', 'company', domain], company);

        if (
          company &&
          COMPANY.current?.name.id !== company.name.id &&
          domain !== 'https://api.tactile.events'
        ) {
          // console.log('useCompany success', COMPANY.current, result, domain);
          if (
            company.settings &&
            company.settings.localization &&
            company.settings.localization.length > 0
          ) {
            const nextLocale = resetLocale(company.settings.localization);
            await CURRENT_LOCALE.emit(nextLocale);
          } else {
            const nextLocale = resetLocale();
            await CURRENT_LOCALE.emit(nextLocale);
          }

          await COMPANY.emit(company);
        }
      })
      .then(() =>
        login({
          // one hour
          expiresAt: expires,
          token,
          domainFull: domain,
        })
      );
  };

  window.addEventListener('message', onSettingsMessage);
}
