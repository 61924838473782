import { useLocalization } from '@introcloud/blocks';
import { useRoute } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { HelperText } from 'react-native-paper';
import { EXPERIENCE_ENABLED } from '../features';
import { useTab, useTabConfiguration, useTabTitle } from '../hooks/useTab';
import { useTabColor } from '../hooks/useTabColor';
import { BlockProvidedPage } from '../page/Page';
import { CustomScreen } from './CustomScreen';

export function CustomTab() {
  const configuration = useTabConfiguration('custom');
  const title = useTabTitle('custom');

  const destination = configuration.destination.value;
  if (destination && !destination.includes(':')) {
    return <SecondInformationScreen page={destination} />;
  }

  const {
    destination: { value },
    passAuth,
    immersive,
  } = configuration || { destination: {} };

  if (value && !value.startsWith('http')) {
    return <InvalidCustomTab value={value} />;
  }

  return (
    <CustomScreen
      title={title}
      url={value || undefined}
      passAuth={passAuth === true ? undefined : passAuth || undefined}
      mode={immersive ? 'immersive' : 'normal'}
      asTab
    />
  );
}

export function CustomAltTab() {
  const configuration = useTabConfiguration('custom2');
  const title = useTabTitle('custom2');

  if (!configuration) {
    return <InvalidCustomTab value={undefined} />;
  }

  const destination = configuration.destination.value;
  if (destination && !destination.includes(':')) {
    return <SecondInformationScreen page={destination} />;
  }

  const {
    destination: { value },
    passAuth,
    immersive,
  } = configuration || { destination: {} };

  if (value && !value.startsWith('http')) {
    return <InvalidCustomTab value={value} />;
  }

  return (
    <CustomScreen
      title={title}
      url={value || undefined}
      passAuth={passAuth === true ? undefined : passAuth || undefined}
      mode={immersive ? 'immersive' : 'normal'}
      asTab
    />
  );
}

export function ExperienceScreen() {
  const { id } = useRoute().params as { id: string };

  if (!EXPERIENCE_ENABLED || !id) {
    return (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <HelperText type="error">This experience is not enabled</HelperText>
      </View>
    );
  }

  const value = 'https://www.rijksmuseum.nl/nl/ontdek-meesterwerken';

  return (
    <CustomScreen
      title="Experience"
      url={value || undefined}
      passAuth={undefined}
      mode="normal"
    />
  );
}

function SecondInformationScreen({ page }: { page: string }) {
  const color = useTabColor('custom');
  return (
    <BlockProvidedPage
      page={page}
      hideBack
      colorOverride={color}
      screen="SecondInformationScreen"
    />
  );
}

function InvalidCustomTab({ value }: { value: string | null | undefined }) {
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <HelperText type="error">{value} is not a valid "custom" url.</HelperText>
    </View>
  );
}
