import { fetchApplication } from '@introcloud/api-client';
import { makeRedirectUri } from 'expo-auth-session';
import Constants from 'expo-constants';
import * as WebBrowser from 'expo-web-browser';
import { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { EXPO_SLUG } from '../config';
import { login } from '../core/Authentication';
import { COMPANY } from '../hooks/useCompany';
import { NATIVE_DOMAIN } from '../open';
import { SHOULD_DEBUG_FETCH } from '../utils';

const DEFAULT_DISCOVERY = {
  authorizationEndpoint: 'https://connect.surfconext.nl/oidc/authorize',
  tokenEndpoint: 'https://connect.surfconext.nl/oidc/token',
};

export function useSurfAuth(
  domainFull: string,
  clientId: string,
  authorize: string,
  token: string
) {
  const [exchange, setExchange] = useState({ code: '', redirectUri: '' });
  const api = `${domainFull}/api/public/auth-surf-conext/redirect`;

  const callback = useCallback(() => {
    setExchange({ code: '', redirectUri: '' });

    const returnTo = makeRedirectUri({
      ...(NATIVE_DOMAIN && Platform.OS !== 'ios'
        ? { native: `https://${NATIVE_DOMAIN}` }
        : {}),
      scheme: EXPO_SLUG,
      queryParams: { domainFull },
    });

    const url = `${api}?returnPath=${encodeURIComponent(returnTo)}`;

    return fetch(url, {
      headers: { accept: 'application/json' },
      method: 'POST',
    })
      .then((response) => response.json())
      .then((json) => {
        return WebBrowser.openAuthSessionAsync(json['data'], returnTo, {
          showInRecents: false,
          showTitle: false,
          enableBarCollapsing: true,
          enableDefaultShareMenuItem: false,
        })
          .then((result) => {
            console.log('auth-session', { result });
            if (result.type === 'success') {
              if (result.url.includes('code=')) {
                const code = (result.url.split('?').pop() ?? '')
                  .split('&')
                  .find((part) => part.startsWith('code='))
                  ?.split('=')
                  .pop();

                const redirectUri = (result.url.split('?').pop() ?? '')
                  .split('&')
                  .find((part) => part.startsWith('redirect-uri='))
                  ?.split('=')
                  .pop();

                if (code && redirectUri) {
                  setExchange({ code, redirectUri });
                }
              }
            }
          })
          .catch((err) => console.error(err));
      });
  }, []);

  useEffect(() => {
    if (!exchange || !exchange.code || !exchange.redirectUri) {
      return;
    }

    const api = `${domainFull}/api/public/auth-surf-conext/sign-up`;
    const url = `${api}?code=${exchange.code}&redirectUri=${exchange.redirectUri}`;

    fetchApplication(domainFull + '/api', undefined, SHOULD_DEBUG_FETCH)
      .then((company) => COMPANY.emit(company))
      .then(() =>
        fetch(url, {
          headers: { accept: 'application/json' },
          method: 'POST',
        })
      )
      .then((r) => {
        if (r.ok) {
          return r.json();
        }

        throw new Error('Could not log you in: ' + r.status);
      })
      .then(
        (response) =>
          response as { data: { token: { value: string; expires: number } } }
      )
      .then(({ data: { token: permit } }) =>
        login({
          domainFull,
          token: permit.value,
          expiresAt: permit.expires,
        })
      )
      .catch((e) => console.error(e.message));
  }, [exchange]);

  return callback;
}
