import 'react-native-gesture-handler';
import 'react-native-get-random-values';
import '@expo/match-media';

import './src/hotfix';
import './src/sentry';
import './src/storage';
import './src/warnings';
import './src/core/screens';
import './src/polyfills/promise';
import './src/localize';

import React from 'react';
import { Root } from './src/Root';

export default function App() {
  return <Root />;
}
