import { useBlockNavigation } from '@introcloud/blocks-interface';
import Color from 'color';
import { fetchMedia, FetchMediaError } from 'fetch-media';
import { t } from 'i18n-js';
import React, { Fragment } from 'react';
import { FlatList, ListRenderItemInfo, Platform, View } from 'react-native';
import {
  Appbar,
  Avatar,
  List,
  Surface,
  Text,
  useTheme,
} from 'react-native-paper';
import { useMutation, useQuery } from 'react-query';
import { BlockProvision } from '../core/BlockProvision';
import { EmptyState } from '../core/EmptyState';
import { Header } from '../core/Header';
import { queryClient } from '../core/QueryCache';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';
import {
  AnyMessage,
  ChatMembership,
  ChatMessage,
  useStoredChats,
} from '../hooks/useChats';
import { useCompany } from '../hooks/useCompany';
import { useCompanyTab } from '../hooks/useCompanyTabs';
import { useForceUpdate } from '../hooks/useForceUpdate';
import { useNameFetcher } from '../hooks/useNameFetcher';
import { useUserSettings } from '../hooks/useUser';
import { SHOULD_ALLOW_DEBUG } from '../utils';
import { useChannelMessageListener } from './ProvideInAppChats';
import { StoredChatMessage, useLastMessage } from './useChatHistory';
import { useChatMentorFlag } from './useChatMentorFlag';
import { useChatName } from './useChatName';
import { useChatOneOnOneUser } from './useChatOneOnOneUser';

export function ChatsScreen({ asTab }: { asTab?: boolean }) {
  const { tab, icon, title } = useCompanyTab('chats', 'forum');

  const chats = useStoredChats();
  const hasChats = !!(
    chats &&
    (chats.length > 1 || (chats.length > 0 && chats[0].custom.type !== 'self'))
  );

  return (
    <BlockProvision screen="ChatsScreen">
      <View
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          maxHeight: Platform.select({ web: '100vh', default: '100%' }),
        }}
      >
        <Header
          title={title || tab?.title || t('app.chats.title')}
          subTitle={undefined}
          hideBack={asTab}
          style={hasChats ? {} : { elevation: 2, zIndex: 2 }}
        >
          <ChatsNotificationToggle />
        </Header>
        {hasChats ? <ChatsList chats={chats!} /> : null}
        <EmptyState
          hidden={hasChats}
          icon={icon}
          title={title || tab?.title || t('app.chats.title')}
          texts={{ en: t('app.chats.empty_state') }}
        />
      </View>
    </BlockProvision>
  );
}

function ChatsList({ chats }: { chats: readonly ChatMembership[] }) {
  return (
    <FlatList
      nativeID="scroller"
      style={{ flex: 1 }}
      contentContainerStyle={{
        maxWidth: 720,
        alignSelf: 'center',
        width: '100%',
      }}
      data={chats}
      renderItem={renderItem}
      keyExtractor={extractKey}
      initialNumToRender={15}
    />
  );
}

function extractKey(item: ChatMembership) {
  return item.id;
}

type Item = ChatMembership;

function renderItem(info: ListRenderItemInfo<Item>) {
  // No custom data, so don't know how to deal with it
  if (!info.item.custom) {
    return null;
  }

  return (
    <ChatRoom
      item={info.item}
      index={info.index}
      separators={info.separators}
      key={info.item.id}
    />
  );
}

function ChatRoom_({ item }: ListRenderItemInfo<ChatMembership>) {
  const forceUpdate = useForceUpdate();

  useChannelMessageListener(item.id, forceUpdate);

  const ListComponent = listComponent(item.custom.type);

  return (
    <Surface style={{ elevation: 1, width: '100%' }}>
      <ListComponent item={item} />
    </Surface>
  );
}

function listComponent(kind: ChatMembership['custom']['type']) {
  switch (kind) {
    case 'page-1-on-1':
    case 'page':
      return PageChatRoom;
    case 'page-group':
      return PageGroupChatRoom;
    case 'group':
      return GroupChatRoom;
    case '1-on-1':
      return UserChatRoom;
    case 'self':
      return SelfChatRoom;
    default:
      return () => null;
  }
}

function PageChatRoom({ item }: { item: ChatMembership }) {
  const lastMessage = useLastMessage(item.id);
  const page = item.custom.refs.find((ref) => ref.model === 'page');

  const nameFetcher = useNameFetcher('page');
  const { data: name } = useQuery(
    ['chat', 'page', page?.id, 'name'],
    ({ signal }) => nameFetcher(page!.id, { signal }),
    {
      enabled: !!page?.id,
      staleTime: 15 * 60 * 1000,
    }
  );

  return (
    <GenericChatRoomListItem
      channelId={item.id}
      channelName={name}
      channelKind={item.custom.type}
      lastMessage={lastMessage}
      renderIcon={(props) => <List.Icon {...props} icon="forum" />}
    />
  );
}

function GroupChatRoom({ item }: { item: ChatMembership }) {
  const group = item.custom.refs.find((ref) => ref.model === 'group');

  const lastMessage = useLastMessage(item.id);
  const groupName = useChatName(group, 'group');

  return (
    <GenericChatRoomListItem
      renderIcon={(props) => <List.Icon {...props} icon="account-group" />}
      channelId={item.id}
      channelName={groupName}
      channelKind={item.custom.type}
      lastMessage={lastMessage}
    />
  );
}

function PageGroupChatRoom({ item }: { item: ChatMembership }) {
  const page = item.custom.refs.find((ref) => ref.model === 'page');
  const group = item.custom.refs.find((ref) => ref.model === 'group');

  const lastMessage = useLastMessage(item.id);
  const groupName = useChatName(group, 'group');
  const pageName = useChatName(page, 'page');

  return (
    <GenericChatRoomListItem
      renderIcon={(props) => <List.Icon {...props} icon="forum" />}
      channelId={item.id}
      channelName={`${pageName || '-'} (${groupName || '-'})`}
      channelKind={item.custom.type}
      lastMessage={lastMessage}
    />
  );
}

function UserChatRoom({ item }: { item: ChatMembership }) {
  const lastMessage = useLastMessage(item.id);
  const { image, name, initials } = useChatOneOnOneUser(item, 'icon_64');
  const {
    colors: { primary },
  } = useTheme();

  return (
    <GenericChatRoomListItem
      renderIcon={(props) => <ChatAvatar image={image} initials={initials} />}
      channelName={name}
      channelId={item.id}
      channelKind={item.custom.type}
      lastMessage={lastMessage}
    />
  );
}

function ChatAvatar({
  image,
  initials,
}: {
  image: string | null;
  initials: string;
}) {
  const {
    colors: { primary, background },
  } = useTheme();

  if (image) {
    return (
      <Avatar.Image
        source={{ uri: image, width: 64, height: 64 }}
        size={40}
        style={{
          marginLeft: 4,
          marginRight: 12,
          marginTop: 8,
          marginBottom: 8,
          backgroundColor: background,
        }}
      />
    );
  }

  const color = new Color(primary);

  return (
    <Avatar.Text
      label={initials}
      size={40}
      style={{ marginLeft: 4, marginRight: 12, marginTop: 8, marginBottom: 8 }}
      color={color.isDark() ? '#fff' : '#000'}
    />
  );
}

function SelfChatRoom({ item }: { item: ChatMembership }) {
  const lastMessage = useLastMessage(item.id);

  if (!SHOULD_ALLOW_DEBUG) {
    return null;
  }

  return (
    <GenericChatRoomListItem
      renderIcon={(props) => <List.Icon {...props} icon="message-cog" />}
      channelName="System"
      channelId={item.id}
      channelKind={item.custom.type}
      lastMessage={lastMessage}
      disabled
    />
  );
}

function GenericChatRoomListItem({
  channelId,
  channelKind,
  channelName,
  lastMessage,
  renderIcon,
  disabled,
}: {
  channelId: string;
  channelKind: string;
  channelName?: string;
  lastMessage?: ReturnType<typeof useLastMessage>;
  renderIcon: React.ComponentProps<typeof List.Item>['left'];
  disabled?: boolean;
}) {
  const { gotoChat } = useBlockNavigation();

  return (
    <List.Item
      left={renderIcon}
      title={channelName || '-'}
      titleStyle={{ fontWeight: lastMessage?.unread ? 'bold' : 'normal' }}
      descriptionNumberOfLines={3}
      disabled={disabled}
      description={(props) => (
        <LastMessage
          {...props}
          message={lastMessage?.message}
          kind={channelKind}
        />
      )}
      right={
        disabled
          ? undefined
          : (props) => (
              <List.Icon
                icon="chevron-right"
                {...props}
                style={{ ...props.style, marginHorizontal: 8 }}
              />
            )
      }
      onPress={() => {
        gotoChat(channelId);
      }}
    />
  );
}

function LastMessage({
  message,
  kind,
  color,
  fontSize,
  ellipsizeMode,
}: {
  message: StoredChatMessage | undefined;
  kind: string;
  color: string;
  fontSize: number;
  ellipsizeMode: any;
}) {
  const name = useChatName(
    message ? { id: message.publisher } : undefined,
    'user'
  );

  const isMentor = useChatMentorFlag(message?.publisher);
  const content = assertChatMessage(message?.message)
    ? message?.message.c || normalize(kind)
    : normalize(kind);

  return (
    <Text
      numberOfLines={3}
      style={{ color, fontSize, marginTop: 4 }}
      ellipsizeMode={ellipsizeMode}
    >
      {content ? (
        <Fragment>
          {name ? (
            <Text
              style={{ fontSize, fontWeight: 'bold', color, marginRight: 4 }}
            >
              {isMentor ? '⭐ ' : ''}
              {name}:
            </Text>
          ) : null}
          {content}
        </Fragment>
      ) : (
        normalize(kind)
      )}
    </Text>
  );
}

function assertChatMessage(
  message: AnyMessage | undefined
): message is ChatMessage {
  if (!message) {
    return false;
  }

  return message.t[0] === 'c';
}

function normalize(kind: string) {
  return (
    (t('app.chats.kinds') as unknown as Record<string, string>)[kind] ||
    t('app.chats.kinds.default')
  );
}

const ChatRoom = React.memo(ChatRoom_);

function ChatsNotificationToggle() {
  const company = useCompany();
  const { data: settings, isLoading } = useUserSettings();
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();

  const disabled =
    (company as any)?.settings?.chatMessageNotification === false;

  const { mutateAsync: setNotifications, isLoading: isSetting } = useMutation<
    unknown,
    FetchMediaError,
    boolean
  >(
    [endpoint, 'application', 'user', authorization, 'settings'],
    async (next: boolean) => {
      await queryClient.cancelQueries([
        endpoint,
        'application',
        'user',
        authorization,
        'settings',
      ]);

      await fetchMedia(
        `${endpoint}/application/user/settings/chat-notification`,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
            contentType: 'application/json',
            authorization: authorization || '',
          },
          body: {
            chatNotification: next,
          },
          disableFormData: true,
          disableFormUrlEncoded: true,
        }
      );
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([
          endpoint,
          'application',
          'user',
          authorization,
          'settings',
        ]);
      },
    }
  );

  const {
    dark,
    colors: { primary },
  } = useTheme();

  if (settings?.chatNotification === undefined || disabled) {
    return null;
  }

  return (
    <Appbar.Action
      color={dark || new Color(primary).isDark() ? 'white' : 'black'}
      icon={settings.chatNotification ? 'bell-ring' : 'bell-off'}
      disabled={isSetting || isLoading}
      accessibilityLabel={
        settings.chatNotification
          ? 'Turn notifications off'
          : 'Turn notifications on'
      }
      accessibilityValue={{
        text: settings.chatNotification
          ? 'Notifications are on'
          : 'Notifications are off',
      }}
      onPress={() => setNotifications(!settings.chatNotification)}
    />
  );
}
