import {
  fetchApplicationPage,
  TactileChatInfo,
  triggerChatNotifications,
} from '@introcloud/api-client';
import { VideoStreamBlockOptions } from '@introcloud/page';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import Color from 'color';
import { t } from 'i18n-js';
import { MessageActionEvent } from 'pubnub';
import { usePubNub } from 'pubnub-react';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  KeyboardEvent,
  Platform,
  ScrollView,
  StyleProp,
  TextStyle,
  View,
} from 'react-native';
import {
  Avatar,
  Caption,
  Card,
  Portal,
  ProgressBar,
  Text,
  TextInput,
  useTheme,
} from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { AutoLinkText, LinkColorProvider } from '../core/AutoLinkText';
import { BlockProvision } from '../core/BlockProvision';
import { RouteProp } from '../core/Routes';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';
import {
  ChatMembership,
  ChatMessage,
  TypedMessageEvent,
  useStoredChats,
} from '../hooks/useChats';
import { useForceUpdate } from '../hooks/useForceUpdate';
import { usePage } from '../hooks/usePage';
import { useUser } from '../hooks/useUser';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { ChatContext } from './ChatContext';
import { HeaderComponent } from './ChatHeader';
import { DayDivider, useChatTimeline } from './DayDivider';
import { PollMessageItem, PollResultMessageItem } from './PollMessage';
import {
  useChannelMessageActionListener,
  useChannelMessageListener,
} from './ProvideInAppChats';
import {
  getHistory,
  markAsRead,
  StoredChatMessage,
  useHistory,
} from './useChatHistory';
import { useChatImage } from './useChatImage';
import { useChatInitials } from './useChatInitials';
import { TactileChatInfoExtended, useChatUserInfo } from './useChatUserInfo';

export function ChatScreen() {
  const { id } = useRoute<RouteProp<'Chat'>>().params;
  const user = useUser();

  const memberships = useStoredChats();
  const membership = useMemo(
    () => memberships?.find((item) => item.id === id),
    [id, memberships]
  );

  const refs = useMemo(() => membership?.custom.refs, [membership]);
  const hasLiveStream = useHasLiveStream(refs || EMPTY_REFS);

  useFocusEffect(
    useCallback(() => {
      return () => {
        markAsRead(id);
      };
    }, [])
  );

  const kind = membership?.custom.type;

  return (
    <BlockProvision screen="ChatScreen">
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          position: 'relative',
          maxHeight: Platform.select({
            web: '100vh',
            default: '100%',
          }),
        }}
      >
        <HeaderComponent kind={kind!} refs={refs || EMPTY_REFS} />
        {user ? (
          <Fragment>
            <ChatMessages
              key={id}
              channel={id}
              kind={kind!}
              refs={refs || EMPTY_REFS}
              hasOverlayInput={64}
            />

            <ChatInput channel={id} hasLiveStream={hasLiveStream} />
          </Fragment>
        ) : null}
      </View>
    </BlockProvision>
  );
}

const EMPTY_REFS: ChatMembership['custom']['refs'] = [];
const EMPTY: TypedMessageEvent[] = [];

export interface ChatMessagesProps {
  channel: string;
  hasOverlayInput: false | number;
  kind?: string;
  refs?: ChatMembership['custom']['refs'];
  children?: React.ReactNode;
  wideReminders?: boolean;
  padWhenKeyboardIsActive?: boolean;
}

export function ChatMessages({
  channel,
  hasOverlayInput,
  kind,
  refs,
  children,
  wideReminders,
  padWhenKeyboardIsActive = true,
}: ChatMessagesProps) {
  const history = useHistory(channel);

  if (history === undefined) {
    return (
      <ProgressBar
        indeterminate
        style={{ alignSelf: 'center', maxWidth: 300, width: '100%' }}
      />
    );
  }

  // This may mutate each render, but will be ignored.
  const intialMessages = history?.messages.slice() || EMPTY.slice();

  return (
    <PureMessagesList
      key={channel}
      channel={channel}
      kind={kind}
      refs={refs}
      initial={intialMessages}
      hasOverlayInput={hasOverlayInput}
      wideReminders={wideReminders}
      padWhenKeyboardIsActive={padWhenKeyboardIsActive}
    >
      {children}
    </PureMessagesList>
  );
}

interface MessagesListProps extends ChatMessagesProps {
  initial: StoredChatMessage[];
}

function MessagesList({
  channel,
  initial,
  hasOverlayInput,
  kind,
  refs,
  children,
  wideReminders,
  padWhenKeyboardIsActive,
}: MessagesListProps) {
  const pubnub = usePubNub();
  const publisher = pubnub.getUUID();
  const listRef = useRef<ScrollView | null>(null);
  const forceUpdate = useForceUpdate();

  const messages = useRef<StoredChatMessage[]>(initial);

  // TODO: on publish, optimistically write, and then check that the message
  // actually appears within x time. Otherwise reconnect.

  const onMessageReceived = useCallback(
    (message: TypedMessageEvent) => {
      // If message exists, ignore
      if (
        messages.current!.find(
          (stored) => stored.timetoken === message.timetoken
        )
      ) {
        return;
      }

      messages.current!.push(message);
      forceUpdate();

      setTimeout(
        () =>
          listRef.current && listRef.current.scrollToEnd({ animated: true }),
        100
      );
    },
    [listRef, forceUpdate, messages]
  );

  const onMessageActionReceived = useCallback(
    (action: MessageActionEvent) => {
      const index = messages.current!.findIndex(
        (stored) => stored.timetoken === action.data.messageTimetoken
      );
      if (index === -1) {
        return;
      }

      const currentMessage = messages.current![index] as TypedMessageEvent;

      switch (action.data.type) {
        case 'deleted': {
          messages.current![index] = {
            ...currentMessage,
            message: {
              f: currentMessage.publisher,
              ...currentMessage.message,
              t: 'c',
              c: '(deleted)',
            },
          };

          getHistory(channel).then((value) =>
            value.emit({
              messages: messages.current,
              read: value.current?.read || 0,
            })
          );

          forceUpdate();
        }
      }
    },
    [forceUpdate, messages]
  );

  useChannelMessageListener(channel, onMessageReceived);
  useChannelMessageActionListener(channel, onMessageActionReceived);

  // On mount, scroll to the bottom, but wait a bit for items to be laid out.
  useEffect(() => {
    setTimeout(
      () => listRef.current && listRef.current.scrollToEnd({ animated: false }),
      200
    );
  }, []);

  const chatMode = kind?.includes('1-on-1') ? 'dialog' : 'chat';

  const renderItem = useCallback(
    (item: StoredChatMessage, index: number, self: StoredChatMessage[]) =>
      renderChatItem(chatMode, item, publisher, self[index - 1]),
    [publisher, chatMode]
  );

  const { bottom } = useSafeAreaInsets();
  const [padding, setPadding] = useState(0);

  useLayoutEffect(() => {
    const onKeyboardShow = ({ endCoordinates }: KeyboardEvent) => {
      setPadding(endCoordinates.height);
    };
    const onKeyboardHide = () => setPadding(0);

    // These only work on IOS and we only have this issue on iOS :)
    const show = Keyboard.addListener('keyboardWillShow', onKeyboardShow);
    const hide = Keyboard.addListener('keyboardWillHide', onKeyboardHide);

    if (show && (typeof show === 'object' || typeof show === 'function')) {
      return () => {
        show.remove();
        hide.remove();
      };
    }

    return () => {
      Keyboard.removeListener('keyboardWillShow', onKeyboardShow);
      Keyboard.removeListener('keyboardWillHide', onKeyboardHide);
    };
  }, []);

  return (
    <LinkColorProvider>
      <Portal.Host>
        <ScrollView
          ref={listRef}
          nativeID="scroller"
          style={{
            flex: 1,
            marginBottom: hasOverlayInput ? hasOverlayInput + bottom : 0,
          }}
          contentContainerStyle={{
            maxWidth: 720,
            alignSelf: 'center',
            paddingBottom: 16 + (padWhenKeyboardIsActive ? padding : 0),
            paddingTop: 16,
            paddingHorizontal: 16,
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Reminder wideReminders={wideReminders} />
          {kind && refs ? <ChatContext kind={kind} refs={refs} /> : null}
          {messages.current.map(renderItem)}
          {children}
        </ScrollView>
      </Portal.Host>
    </LinkColorProvider>
  );
}

function useHasLiveStream(refs: NonNullable<MessagesListProps['refs']>) {
  const pageRef = useMemo(
    () => refs.find((ref) => ref.model === 'page'),
    [refs]
  );
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();

  const getInfoById = useCallback(
    (pageId: string) =>
      fetchApplicationPage(
        pageId,
        endpoint,
        authorization || '',
        undefined,
        SHOULD_DEBUG_FETCH
      ),
    [endpoint, authorization]
  );

  const { page } = usePage(pageRef?.id, getInfoById);

  const liveStreamBlock = page?.content?.find(
    (block) => block.kind === 'live'
  ) as (VideoStreamBlockOptions & { _id: string }) | undefined;

  return Boolean(liveStreamBlock);
}

function Reminder({ wideReminders }: { wideReminders?: boolean }) {
  if (wideReminders) {
    return (
      <Caption style={{ marginBottom: 16, marginTop: 8 }}>
        {t('app.chats.privacy')}
      </Caption>
    );
  }

  return (
    <Card style={{ marginBottom: 16 }}>
      <Card.Content>
        <Caption>{t('app.chats.privacy')}</Caption>
      </Card.Content>
    </Card>
  );
}

const PureMessagesList = React.memo(MessagesList);

function extractKey(item: StoredChatMessage) {
  return [item?.timetoken, item.message.t].join('.');
}

export function ChatInput({
  channel,
  hasLiveStream,
}: {
  channel: string;
  hasLiveStream?: boolean;
}) {
  const { bottom } = useSafeAreaInsets();
  const publish = usePublishChat(channel, hasLiveStream !== true);
  const [input, setInput] = useState('');

  const [shouldUseSafeInsets, setUseSafeInsets] = useState(true);

  useLayoutEffect(() => {
    const onKeyboardShow = ({ endCoordinates }: KeyboardEvent) => {
      setUseSafeInsets(false);
    };
    const onKeyboardHide = () => setUseSafeInsets(true);

    // These only work on IOS and we only have this issue on iOS :)
    const show = Keyboard.addListener('keyboardWillShow', onKeyboardShow);
    const hide = Keyboard.addListener('keyboardWillHide', onKeyboardHide);

    if (show && (typeof show === 'object' || typeof show === 'function')) {
      return () => {
        show.remove();
        hide.remove();
      };
    }

    return () => {
      Keyboard.removeListener('keyboardWillShow', onKeyboardShow);
      Keyboard.removeListener('keyboardWillHide', onKeyboardHide);
    };
  }, []);

  return (
    <MaybeAvoiding>
      <TextInput
        style={{
          maxWidth: 720,
          alignSelf: 'center',
          width: '100%',
          position: 'absolute',
          bottom: 0,
          paddingBottom: shouldUseSafeInsets ? bottom : 0,
          zIndex: 1,
        }}
        returnKeyType="send"
        placeholder={t('app.chats.placeholder')}
        value={input}
        onSubmitEditing={(e) => {
          const text = e.nativeEvent.text;
          if (text && text.trim()) {
            publish(text);
            setInput('');
          }
        }}
        onChangeText={setInput}
        enablesReturnKeyAutomatically
        autoCapitalize="sentences"
        autoCorrect
        blurOnSubmit={false}
        clearButtonMode="while-editing"
        right={
          <TextInput.Icon
            disabled={!input || !input.trim()}
            name="send"
            accessibilityLabel={t('app.chats.action_send')}
            onPress={() => {
              publish(input.trim());
              setInput('');
            }}
          />
        }
      />
    </MaybeAvoiding>
  );
}

function MaybeAvoiding({ children }: React.PropsWithChildren<object>) {
  if (Platform.OS === 'ios') {
    return (
      <KeyboardAvoidingView behavior="position">
        {children}
      </KeyboardAvoidingView>
    );
  }
  return <Fragment>{children}</Fragment>;
}

function renderChatItem(
  mode: 'chat' | 'dialog',
  item: StoredChatMessage,
  publisher: string,
  previous?: StoredChatMessage
) {
  const key = extractKey(item);
  const isSelf = publisher === item.publisher;

  switch (item.message.t) {
    case 'c': {
      return (
        <ChatMessageItem
          key={key}
          item={item}
          previousItem={previous}
          message={item.message}
          isSelf={isSelf}
          mode={mode}
        />
      );
    }
    case 'chat': {
      return (
        <ChatMessageItem
          key={key}
          item={item}
          previousItem={previous}
          message={item.message}
          isSelf={isSelf}
          mode={mode}
        />
      );
    }

    case 'poll': {
      return (
        <PollMessageItem
          key={key}
          item={item}
          previousItem={previous}
          message={item.message}
        />
      );
    }

    case 'poll-result': {
      return (
        <PollResultMessageItem
          key={key}
          item={item}
          previousItem={previous}
          message={item.message}
        />
      );
    }
  }
  return null;
}

type ChatMessageProps = {
  mode: 'chat' | 'dialog';
  item: StoredChatMessage;
  previousItem: StoredChatMessage | undefined;
  message: ChatMessage;
  isSelf: boolean;
};

function ChatMessageItem_({
  mode,
  item,
  previousItem,
  isSelf,
  message,
}: ChatMessageProps) {
  const user = item.publisher || message.f;

  const { messageTime, isNewDay, isSame } = useChatTimeline(item, previousItem);

  const info = useChatUserInfo({ id: user });

  switch (mode) {
    case 'dialog': {
      return (
        <Fragment>
          {isNewDay ? <DayDivider day={messageTime} /> : null}
          <DialogMessageLine
            info={info}
            message={message.c}
            isSelf={isSelf}
            isSame={isSame}
          />
        </Fragment>
      );
    }

    case 'chat': {
      return (
        <Fragment>
          {isNewDay ? <DayDivider day={messageTime} /> : null}
          <ChatMessageLine
            info={info}
            message={message.c}
            messageTime={messageTime}
            isSame={isSame}
          />
        </Fragment>
      );
    }
  }
}

function DialogMessageLine({
  info,
  message,
  isSelf,
  isSame,
}: {
  info: TactileChatInfoExtended | undefined;
  message: string;
  isSelf: boolean;
  isSame: boolean;
}) {
  return (
    <View style={{ width: '100%' }}>
      {isSame ? null : (
        <Text
          style={{
            alignSelf: isSelf ? 'flex-end' : 'flex-start',
            textAlign: isSelf ? 'right' : 'left',
            marginTop: 14,
          }}
        >
          <ChatName info={info} />
        </Text>
      )}
      <View
        style={{
          alignSelf: isSelf ? 'flex-end' : 'flex-start',
          paddingLeft: isSelf ? 32 : 0,
          paddingRight: isSelf ? 0 : 32,
        }}
      >
        <AutoLinkText
          textAlign={isSelf ? 'right' : 'left'}
          alignSelf={isSelf ? 'flex-end' : 'flex-start'}
        >
          {message}
        </AutoLinkText>
      </View>
    </View>
  );
}

function ChatMessageLine({
  info,
  message,
  messageTime,
  isSame,
}: {
  info: TactileChatInfoExtended | undefined;
  message: string;
  messageTime: Date;
  isSame: boolean;
}) {
  return (
    <View
      style={{
        width: '100%',
        minHeight: isSame ? undefined : 44,
        marginTop: isSame ? undefined : 12,
      }}
    >
      {isSame ? null : (
        <ChatMessageHeader info={info} messageTime={messageTime} />
      )}
      <View style={{ width: '100%', paddingLeft: 52, marginRight: 16 }}>
        <AutoLinkText alignSelf="flex-start">{message}</AutoLinkText>
      </View>
    </View>
  );
}

function ChatMessageHeader({
  info,
  messageTime,
}: {
  info: TactileChatInfoExtended | undefined;
  messageTime: Date;
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignSelf: 'flex-start',
      }}
    >
      <View style={{ position: 'absolute' }}>
        <ChatAvatar info={info} />
      </View>
      <View
        style={{
          paddingLeft: 52,
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          maxWidth: '100%',
        }}
      >
        <ChatName
          info={info}
          style={{
            marginRight: 8,
          }}
        />
        <Caption
          style={{
            fontSize: 11,
            marginTop: 0,
            marginBottom: 0,
            lineHeight: 16,
          }}
        >
          {messageTime.toLocaleTimeString()}
        </Caption>
      </View>
    </View>
  );
}

function ChatAvatar({ info }: { info: TactileChatInfo | undefined }) {
  const image = useChatImage(info, 'icon_64');
  const initials = useChatInitials(info);
  const {
    colors: { primary, surface },
  } = useTheme();

  if (image) {
    return (
      <Avatar.Image
        source={{ uri: image, width: 64, height: 64 }}
        size={40}
        style={{ marginRight: 8, backgroundColor: surface }}
      />
    );
  }

  const color = new Color(primary);

  return (
    <Avatar.Text
      label={initials}
      size={40}
      style={{ marginRight: 8 }}
      color={color.isDark() ? '#fff' : '#000'}
    />
  );
}

function ChatName({
  info,
  style,
  align = 'left',
}: {
  info: TactileChatInfoExtended | undefined;
  style?: StyleProp<TextStyle>;
  align?: 'right' | 'left';
}) {
  return (
    <Text
      style={[
        {
          fontWeight: 'bold',
          textAlign: align,
        },
        style,
      ]}
    >
      {info?.isMentor ? '⭐ ' : ''}
      {info?.name?.full || t('app.chats.anonymous')}
    </Text>
  );
}

const ChatMessageItem = React.memo(ChatMessageItem_);

function createChatMessage(message: string, me: string): ChatMessage {
  return {
    t: 'chat',
    c: message,
    f: me,
  };
}

export function usePublishChat(channel: string, withNotifications = true) {
  const pubnub = usePubNub();
  const uuid = pubnub.getUUID();
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();

  return useCallback(
    async (message: string) => {
      return pubnub
        .publish({
          channel,
          message: createChatMessage(message, uuid),
        })
        .then(() => {
          if (withNotifications) {
            return triggerChatNotifications(
              channel,
              message,
              endpoint,
              authorization || ''
            );
          }
        })
        .catch((error) => console.error(error, error.status));
    },
    [pubnub, channel, uuid, authorization, endpoint, withNotifications]
  );
}
