import cloneDeep from 'lodash.clonedeep';
import { MessageActionEvent, MessageEvent } from 'pubnub';
import { usePubNub } from 'pubnub-react';
import { useCallback, useEffect, useState } from 'react';
import { StoredMemoryValue } from '../storage';
import { CHATS, CHATS_ORDER, TypedMessageEvent } from '../hooks/useChats';
import { useChannelMessageActionListener } from './ProvideInAppChats';

const CHAT_HISTORIES: Record<string, StoredMemoryValue<ChatHistory>> = {};

export type StoredChatMessage = Pick<
  TypedMessageEvent,
  'message' | 'publisher' | 'timetoken'
>;

export type ChatHistory = {
  messages: StoredChatMessage[];
  read: number;
};

const EMPTY: ChatHistory = { messages: [], read: Number.MIN_SAFE_INTEGER };
const QUEUED_ORDER: Record<string, string> = {};

function processQueue() {
  // const nextChats = CHATS.current?.slice();
  const nextChatsOrder = CHATS_ORDER.current || {};

  if (!CHATS.current) {
    return;
  }

  for (const channel in QUEUED_ORDER) {
    const index = CHATS.current.findIndex((chat) => chat.id === channel);
    if (index !== -1) {
      // nextChats[index] = { ...nextChats[index], updated: QueuedOrder[channel] };
      nextChatsOrder[channel] = QUEUED_ORDER[channel];
    }

    delete QUEUED_ORDER[channel];
  }

  // CHATS.emit(nextChats.sort((a, b) => b.updated.localeCompare(a.updated)));
  CHATS_ORDER.emit(nextChatsOrder, true, false);

  console.debug('[processed queue] for chats');
}

export async function storeMessage(messageEvent: MessageEvent) {
  const channel = messageEvent.channel;

  if (channel.startsWith('game-map.')) {
    return;
  }

  const memory = await getHistory(channel);
  const current = memory.current || cloneDeep(EMPTY);

  // Message already exists
  if (
    current.messages.find(
      (exists) => exists.timetoken === messageEvent.timetoken
    )
  ) {
    return;
  }

  const { message, publisher, timetoken } = messageEvent;

  const messageDate = new Date(
    Number(timetoken.slice(0, timetoken.length - 4))
  ).toISOString();

  if (
    !QUEUED_ORDER[channel] ||
    QUEUED_ORDER[channel].localeCompare(messageDate) < 0
  ) {
    QUEUED_ORDER[channel] = messageDate;
  }

  requestAnimationFrame(processQueue);

  memory.emit({
    messages: current.messages
      .concat([{ message, publisher, timetoken }])
      .slice(-100),
    read: current.read,
  });
}

export async function getHistory(
  channel: string
): Promise<StoredMemoryValue<ChatHistory>> {
  if (CHAT_HISTORIES[channel]) {
    return CHAT_HISTORIES[channel];
  }

  const value = new StoredMemoryValue<ChatHistory>(
    `chats.history.${channel}.v1`,
    false
  );

  CHAT_HISTORIES[channel] = value;

  if (value.current !== undefined) {
    return CHAT_HISTORIES[channel];
  }

  return value
    .hydrate()
    .then(() => {
      if (!value.current) {
        return value.emit(cloneDeep(EMPTY));
      }
    })
    .then(() => value);
}

const HISTORY_RETRIEVED_SESSIONS: Record<string, boolean> = {};

export function useHistory(channel: string) {
  const pubnub = usePubNub();
  const [history, setHistory] = useState<ChatHistory | null | undefined>(
    CHAT_HISTORIES[channel]?.current
  );

  useEffect(() => {
    let stillCareAboutThis = true;
    let unsubscribe = () => {};

    getHistory(channel)
      .then((value) => {
        if (!stillCareAboutThis) {
          return value;
        }

        if (HISTORY_RETRIEVED_SESSIONS[channel]) {
          return value;
        }

        HISTORY_RETRIEVED_SESSIONS[channel] = true;

        return pubnub
          .history({
            channel,
            count: 100,
            stringifiedTimeToken: true,
            includeTimetoken: true,
          })
          .then((result) => {
            const now = value.current || cloneDeep(EMPTY);
            const converted = result.messages
              .map((historyMessage) => {
                try {
                  const { timetoken, entry: message } = historyMessage;
                  return {
                    timetoken,
                    message,
                    publisher: message.f,
                  } as StoredChatMessage;
                } catch {
                  return null;
                }
              })
              .filter(Boolean) as StoredChatMessage[];

            // TODO: could use binsearch
            const nextMessages = now.messages
              .concat(converted)
              .sort((a, b) => a.timetoken.localeCompare(b.timetoken))
              .filter(
                (message, index, self) =>
                  self.findIndex((m) => m.timetoken === message.timetoken) ===
                  index
              )
              .slice(-100);

            const lastMessage = nextMessages[nextMessages.length - 1];

            if (lastMessage) {
              const messageDate = new Date(
                Number(
                  lastMessage.timetoken.slice(
                    0,
                    lastMessage.timetoken.length - 4
                  )
                )
              ).toISOString();

              QUEUED_ORDER[channel] = messageDate;
              setTimeout(processQueue);
            }

            return value
              .emit({
                messages: nextMessages,
                read: now.read,
              })
              .then(() => value);
          });
      })
      .then((value) => {
        if (!stillCareAboutThis) {
          return;
        }

        setHistory(value.current);
        value.subscribe(setHistory);

        unsubscribe = () => value.unsubscribe(setHistory);
      })
      .catch((e) => {
        console.error(e);
      });

    return () => {
      stillCareAboutThis = false;
      unsubscribe();
    };
  }, [channel]);

  return history;
}

export async function markAsRead(channel: string) {
  const now = new Date();
  const history = await getHistory(channel);

  return history.emit({
    read: now.getTime(),
    messages: history.current?.messages || cloneDeep(EMPTY.messages),
  });
}

export function useLastMessage(
  channel: string
): undefined | { unread: boolean; message: StoredChatMessage } {
  const history = useHistory(channel);

  if (!history) {
    return undefined;
  }

  const count = history.messages.length;
  for (let i = count - 1; i >= 0; i--) {
    const current = history.messages[i];

    // Find the last chat message
    if (current.message.t[0] === 'c') {
      return {
        unread:
          history.read <
          Number(current.timetoken.slice(0, current.timetoken.length - 4)),
        message: current,
      };
    }
  }

  return undefined;
}
