import { motion, useAnimation } from 'framer-motion';
import React, { Component, memo, useCallback, useEffect, useRef } from 'react';
import {
  Dimensions,
  Image,
  ImageSourcePropType,
  StyleSheet,
} from 'react-native';
import { COLOR_PRIMARY } from '../config';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';
import { useCompany } from '../hooks/useCompany';
import { useSplash } from '../hooks/useSplash';
import { openExternalUrl } from '../open';

export interface SplasherProps {
  onDone(): void;
  onReady(): void;
  onLoaded(): void;
  onAlmostDone(): void;

  companyBackgroundColor?: string;
  companyResizeMode?: 'cover' | 'contain';
}

function SplashOrganisation_(props: SplasherProps) {
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();
  const company = useCompany();

  if (endpoint === undefined || authorization === undefined || !company) {
    // console.log({ endpoint, authorization, company });

    return null;
  }

  const {
    application: {
      splash: {
        backgroundColor: companyBackgroundColor,
        resizeMode: companyResizeMode,
      },
    },
  } = company;

  if (authorization && !__DEV__) {
    return (
      <SplashWithSponsor
        {...props}
        companyBackgroundColor={companyBackgroundColor}
        companyResizeMode={companyResizeMode}
      />
    );
  }

  return (
    <Splasher
      {...props}
      companyBackgroundColor={companyBackgroundColor}
      companyResizeMode={companyResizeMode}
    />
  );
}

export const SplashOrganisation = memo(SplashOrganisation_);

function SplashWithSponsor(props: SplasherProps) {
  const splash = useSplash();
  const endpoint = useEndpoint();

  const getImageUrl = useCallback(
    (
      imageId: string,
      targetSize:
        | 'icon_32'
        | 'icon_64'
        | 'icon_128'
        | 'icon_256'
        | 'icon_512'
        | 'icon_720'
        | 'icon_1440'
    ) => {
      if (!imageId || imageId.trim().length === 0) {
        return null;
      }

      return endpoint + `/image/${imageId}/${targetSize}`;
    },
    [endpoint]
  );

  const url = getImageUrl(splash?.image?.profile || '', 'icon_1440');

  if (splash === undefined) {
    return null;
  }

  if (!splash || !url) {
    return <Splasher {...props} />;
  }

  return (
    <Splasher
      {...props}
      partnerBackgroundColor={splash.color?.hex}
      partnerImage={{ uri: url, width: 1440, height: 1440 }}
      partnerResizeMode="contain"
      partnerUrl={splash.url}
    />
  );
}

class Splasher extends Component<
  SplasherProps & {
    partnerBackgroundColor?: string;
    partnerResizeMode?: 'cover' | 'contain';
    partnerImage?: ImageSourcePropType;
    partnerUrl?: string;
  }
> {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return <Splasher_ {...this.props} />;
  }
}

function Splasher_({
  partnerBackgroundColor,
  partnerImage,
  partnerResizeMode,
  partnerUrl,
  companyBackgroundColor,
  companyResizeMode,
  onDone,
  onReady,
  onLoaded,
}: SplasherProps & {
  partnerBackgroundColor?: string;
  partnerResizeMode?: 'cover' | 'contain';
  partnerImage?: ImageSourcePropType;
  partnerUrl?: string;
}) {
  const animation = useAnimation();

  const todo = useRef(partnerImage ? 2 : 1);
  const onLoadedItem = useCallback(async () => {
    todo.current -= 1;

    if (todo.current === 0) {
      onLoaded();

      if (!__DEV__) {
        await animation.start('in');
        await animation.start('company');

        if (partnerImage) {
          await animation.start('switch');
          await animation.start('partner');
        }
      }

      await animation.start('out');
      onDone();
    }
  }, []);

  // Timeout loading, just go with it
  useEffect(() => {
    const clear = setTimeout(() => {
      if (todo.current > 0) {
        onLoadedItem();
      }

      if (todo.current > 0) {
        onLoadedItem();
      }
    }, 1000 * 5);

    return () => {
      clearTimeout(clear);
    };
  }, []);

  const splashImage = require('../../assets/splash.png');
  const { width, height } = Dimensions.get('window');

  const gotoPartner = () =>
    partnerUrl && openExternalUrl(partnerUrl, COLOR_PRIMARY);

  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <motion.div
      animate={animation}
      initial={{
        opacity: 1,
        backgroundColor: companyBackgroundColor,
      }}
      variants={{
        in: { opacity: 1, transition: { duration: 0.5 } },
        company: { opacity: 1, transition: { duration: 1.2 } },
        switch: {
          backgroundColor: partnerBackgroundColor,
          transition: { duration: 0.5 },
        },
        partner: { opacity: 1, transition: { duration: 2.2 } },
        out: {
          opacity: 0,
          backgroundColor: 'rgba(0, 0, 0, 0)',
          transition: { duration: 0.5 },
        },
      }}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        variants={{
          in: { opacity: 1, transition: { duration: 0.3 } },
          switch: {
            opacity: 0,
            transition: { duration: 0.3 },
          },
          out: { opacity: 0, transition: { duration: 0.3 } },
        }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Image
          source={splashImage} // image ? { uri: image } :
          resizeMode={companyResizeMode}
          style={[
            {
              width: '100%',
              height: '100%',
            },
            StyleSheet.absoluteFillObject,
          ]}
          width={width}
          height={height}
          fadeDuration={0}
          onLoadEnd={onLoadedItem}
          onError={onLoadedItem}
        />
      </motion.div>
      {partnerImage ? (
        <motion.div
          onClick={gotoPartner}
          initial={{ opacity: 0 }}
          variants={{
            switch: {
              opacity: 1,
              transition: { duration: 0.5, delay: 0.1 },
            },
            partner: { opacity: 1, transition: { duration: 2.2 } },
            out: { opacity: 0, transition: { duration: 0.3 } },
          }}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            cursor: partnerUrl ? 'pointer' : 'default',
          }}
        >
          <Image
            source={partnerImage} // image ? { uri: image } :
            resizeMode={partnerResizeMode || 'contain'}
            style={[
              {
                width: '100%',
                height: '100%',
              },
            ]}
            width={width}
            height={height}
            fadeDuration={0}
            onLoadEnd={onLoadedItem}
            onError={onLoadedItem}
          />
        </motion.div>
      ) : null}
    </motion.div>
  );
}
