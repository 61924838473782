import { TactileSponsor } from '@introcloud/api-client';
import { Divider } from '@introcloud/blocks';
import {
  useBlockData,
  useBlockDataSponsorById,
  useBlockDataSponsorByKind,
  useBlockSettings,
} from '@introcloud/blocks-interface';
import { SponsorBlock } from '@introcloud/page';
import URLSearchParams from '@ungap/url-search-params';
import Constants from 'expo-constants';
import React, { Fragment } from 'react';
import { PixelRatio, StyleSheet } from 'react-native';
import { EXPO_IDENTIFIER } from '../config';
import { STATIC_SPONSORS } from '../features';

export function StaticSponsor({ day }: { day: string }) {
  if (!STATIC_SPONSORS || !STATIC_SPONSORS[day]) {
    return <RandomSponsor day={day} />;
  }

  const sponsorId = STATIC_SPONSORS[day];
  return <RemoteSponsor sponsorId={sponsorId} day={day} />;
}

export function RemoteSponsor({
  sponsorId,
  day,
}: {
  sponsorId: string;
  day: string;
}) {
  const { getImageUrl } = useBlockData();
  const { data: sponsor } = useBlockDataSponsorById(sponsorId);
  const src = getImageUrl(sponsor?.image.profile || '', 'icon_1440');

  if (!src || !sponsor) {
    return null;
  }

  return <ResolvedSponsor sponsor={sponsor} id={day} />;
}

export function RandomSponsor({ day: id }: { day: string }) {
  const { getImageUrl } = useBlockData();
  const { data: sponsor } = useBlockDataSponsorByKind('event', id);

  const src = getImageUrl(sponsor?.image.profile || '', 'icon_1440');

  if (!src || !sponsor) {
    return null;
  }

  return <ResolvedSponsor sponsor={sponsor} id={id} />;
}

function ResolvedSponsor({
  sponsor,
  utmContent,
  id,
}: {
  sponsor: TactileSponsor;
  utmContent?: string | null | undefined;
  id: string;
}) {
  const [, search] = (sponsor.url || '').split('?');
  const searchParams = new URLSearchParams(search);
  const {
    Advertisement: { ratio },
  } = useBlockSettings();

  return (
    <Fragment>
      <Divider
        style={{
          height: PixelRatio.roundToNearestPixel(StyleSheet.hairlineWidth),
        }}
      />
      <SponsorBlock
        kind="sponsor"
        value={{
          src: sponsor._id,
          utm: {
            source: searchParams.get('utm_source') || EXPO_IDENTIFIER,
            content:
              searchParams.get('utm_content') ||
              utmContent ||
              `EventDayScreen-${id}-SponsorBlock`,
          },
        }}
        id={id}
        options={{ ratio, mode: 'cover' }}
      />
    </Fragment>
  );
}
