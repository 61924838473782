import Constants from 'expo-constants';
import { deviceName, deviceYearClass } from 'expo-device';
import { Platform } from 'react-native';
import { seed } from './seed';

export const IS_TEST_RELEASE =
  process.env.IS_TEST_RELEASE === '1' ||
  Constants.expoConfig?.extra?.IS_TEST_RELEASE === '1';

export const DOMAIN_TACTILE = process.env.INTROCLOUD_DOMAIN_TACTILE;
export const DOMAIN_INTROCLOUD = process.env.INTROCLOUD_DOMAIN_INTROCLOUD;
export const DOMAIN_WHITELABEL = process.env.INTROCLOUD_DOMAIN_WHITELABEL;

export const NATIVE_DOMAIN = DOMAIN_TACTILE || DOMAIN_INTROCLOUD;

export const INTERNAL_PREFIXES = [
  DOMAIN_INTROCLOUD,
  DOMAIN_TACTILE,
  DOMAIN_WHITELABEL,
]
  .filter(Boolean)
  .map((domain) => 'https://' + domain)
  .map((domain) => domain.toLocaleUpperCase());

export const COLOR_ANDROID_ICON = process.env.EXPO_ANDROID_ICON_COLOR;
export const COLOR_PRIMARY = process.env.EXPO_PRIMARY_COLOR;
export const COLOR_SPLASH = process.env.EXPO_SPLASH_COLOR;

export const EXPO_IDENTIFIER = process.env.EXPO_IDENTIFIER!;
export const EXPO_VERSION = process.env.EXPO_VERSION!;
export const EXPO_SLUG = process.env.EXPO_SLUG!;
export const EXPO_NAME = process.env.EXPO_NAME!;
export const EXPO_BUILD_NUMBER = process.env.EXPO_BUILD_NUMBER!;

export const INTROCLOUD_ENDPOINT = process.env.INTROCLOUD_ENDPOINT!;
export const DEFAULT_LOCALE = process.env.INTROCLOUD_LOCALE || 'en';
export const SPLASH_RESIZE_MODE = process.env.EXPO_SPLASH_RESIZE_MODE;

export const EXPERIENCE_SLUG = `@introcloud/${EXPO_SLUG || 'unknown'}`;

export const SEED_COMPANY = {};
export const SEED_PAGES: Record<string, string> = {};

// Attempt to import the seeded pages
seed(SEED_COMPANY, SEED_PAGES);

const likeness = `${Platform.OS} ${Platform.Version} like ${
  deviceName || 'NoName'
}`.replace(/[^-._A-z0-9]/g, '');
export const USER_AGENT = `${EXPO_IDENTIFIER}/${EXPO_VERSION} (${Platform.OS}; ${deviceYearClass}, ${likeness}) Expo/EAS (React-Native; Slug ${EXPO_SLUG}; Build ${EXPO_BUILD_NUMBER})`;
