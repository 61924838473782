import { TactileChatInfo, TactileGroup } from '@introcloud/api-client';
import Color from 'color';
import { t } from 'i18n-js';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, ScrollView, View } from 'react-native';
import {
  Avatar,
  Dialog,
  IconButton,
  List,
  Text,
  TextInput,
  useTheme,
} from 'react-native-paper';
import { useChatImage } from '../chats/useChatImage';
import { useChatInitials } from '../chats/useChatInitials';
import { useGroupsDetached } from '../hooks/useGroup';
import { useCurrencyDisplayOptions } from './useCurrencyDisplay';
import { useTopupAmounts } from './useTopupAmounts';
import { useTransfer } from './useTransfer';

export function SendDialogContent({
  visible,
  showMessage,
}: {
  visible: boolean;
  showMessage: (next: string) => void;
}) {
  const amounts = useTopupAmounts();
  const displayOptions = useCurrencyDisplayOptions();

  const { data: groups, isLoading } = useGroupsDetached({ enabled: visible });
  const [amount, setAmount] = useState<string | null>(null);
  const [nextRecipient, setNextRecipient] = useState('');
  const people = usePeople(groups);

  useEffect(() => {
    setAmount(
      displayOptions.tokens ? '1' : (amounts.amounts[0].cents / 100).toFixed(2)
    );
  }, [displayOptions.rate, amounts.amounts]);

  const { mutateAsync: startTransfer, isLoading: isSending } = useTransfer({
    onSuccess: (_, variables) => {
      setNextRecipient('');
      showMessage(
        t('app.payment.transfer.success', {
          amount: `${displayOptions.symbol} ${
            displayOptions.tokens
              ? variables.amount / 100 / displayOptions.rate
              : (variables.amount / 100).toFixed(2)
          }`,
        })
      );
    },

    onError: (error, variables) => {
      showMessage(
        t('app.payment.transfer.error', {
          amount: `${displayOptions.symbol} ${
            displayOptions.tokens
              ? variables.amount / 100 / displayOptions.rate
              : (variables.amount / 100).toFixed(2)
          }`,
          error: error.message,
        })
      );
    },
  });

  if (isLoading || isSending) {
    return (
      <Fragment>
        <Dialog.Title>{t('app.payment.transfer.title')}</Dialog.Title>
        <View style={{ justifyContent: 'center', minHeight: 200 }}>
          <ActivityIndicator />
        </View>
      </Fragment>
    );
  }

  return (
    <View style={{ position: 'relative' }}>
      <Dialog.Title>{t('app.payment.transfer.title')}</Dialog.Title>

      <View style={{ position: 'relative', marginTop: -6, marginBottom: 6 }}>
        <TextInput
          keyboardType="decimal-pad"
          onChangeText={setAmount}
          disabled={amount === null}
          value={amount ?? ''}
          mode="outlined"
          label={t('app.payment.transfer.label')}
          style={{ marginHorizontal: 12 }}
          left={<TextInput.Affix text={displayOptions.symbol} />}
        />
        <IconButton
          style={{ zIndex: 1, right: 12, top: 11, position: 'absolute' }}
          icon="plus-box"
          onPress={() =>
            setAmount(
              nextAmount(amount, amounts.amounts[0].cents, displayOptions)
            )
          }
          disabled={amount === null || isNaN(parseFloat(amount))}
        />
      </View>

      <Dialog.ScrollArea style={{ paddingHorizontal: 0, maxHeight: 300 }}>
        <ScrollView
          contentContainerStyle={{
            margin: 0,
            paddingHorizontal: 0,
            paddingVertical: 16,
          }}
        >
          {people.map((person) => (
            <List.Item
              key={person._id}
              left={() => <ChatAvatar info={person} />}
              style={{ paddingStart: 16 }}
              title={
                person.name.full ||
                [person.name.first, person.name.last].filter(Boolean).join(' ')
              }
              description={
                nextRecipient === person._id
                  ? t('app.payment.transfer.confirm', { amount })
                  : ' '
              }
              onPress={
                amount && parseFloat(amount) >= 0
                  ? () => setNextRecipient(person._id)
                  : undefined
              }
              right={
                nextRecipient === person._id
                  ? () =>
                      isSending ? (
                        <ActivityIndicator size="small" />
                      ) : (
                        <IconButton
                          icon="send"
                          accessibilityLabel={t('app.payment.actions.transfer')}
                          disabled={
                            amount === null ||
                            isNaN(parseFloat(amount)) ||
                            isSending
                          }
                          onPress={() =>
                            startTransfer({
                              amount: amountToCents(amount, displayOptions),
                              recipientId: person._id,
                            })
                          }
                        />
                      )
                  : undefined
              }
            />
          ))}
        </ScrollView>
      </Dialog.ScrollArea>
    </View>
  );
}

function nextAmount(
  amount: string | null,
  incrementRate: number,
  displayOptions: { tokens: boolean; rate: number }
) {
  if (amount === null) {
    return '';
  }

  if (displayOptions.tokens) {
    const tokens = parseFloat(amount);
    return String(tokens + 1);
  }

  return (parseFloat(amount) + incrementRate / 100).toFixed(2);
}

function amountToCents(
  amount: string | null,
  displayOptions: { tokens: boolean; rate: number }
) {
  if (amount === null) {
    return 0;
  }

  if (displayOptions.tokens) {
    let tokens = parseInt(amount);
    if (amount.includes('.') && amount.split('.').pop() != '5') {
      tokens = Math.round(parseFloat(amount));
    }

    return tokens * displayOptions.rate * 100;
  }

  return parseFloat(amount) * 100;
}

function ChatAvatar({ info }: { info: TactileChatInfo | undefined }) {
  const image = useChatImage(info, 'icon_64');
  const initials = useChatInitials(info);
  const {
    colors: { primary, surface },
  } = useTheme();

  if (image) {
    return (
      <Avatar.Image
        source={{ uri: image, width: 64, height: 64 }}
        size={40}
        style={{ marginRight: 8, backgroundColor: surface }}
      />
    );
  }

  const color = new Color(primary);

  return (
    <Avatar.Text
      label={initials}
      size={40}
      style={{ marginRight: 8 }}
      color={color.isDark() ? '#fff' : '#000'}
    />
  );
}

function usePeople(groups: readonly TactileGroup[] | null | undefined) {
  return useMemo(
    () =>
      groups
        ? groups
            .flatMap((group) => group.users)
            .filter(
              (user, index, self) =>
                self.findIndex((item) => item._id === user._id) === index
            )
            .sort(
              (a, b) =>
                (a.name.full || '').localeCompare(b.name.full || '') ||
                a._id.localeCompare(b._id)
            )
        : [],
    [groups]
  );
}
