import { BalanceOverViewState, fetchBalance } from '@introcloud/api-client';
import { useBlockNavigation } from '@introcloud/blocks-interface';
import { BalanceBlockOptions, BlockProps } from '@introcloud/page';
import { useIsFocused } from '@react-navigation/native';
import { FetchMediaError } from 'fetch-media';
import React from 'react';
import {
  ActivityIndicator,
  Card,
  HelperText,
  List,
  Surface,
  useTheme,
} from 'react-native-paper';
import { useQuery } from 'react-query';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';
import { useCurrencyDisplay } from './useCurrencyDisplay';

export function BalanceBlock(block: BlockProps<BalanceBlockOptions>) {
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();
  const isFocused = useIsFocused();

  const showOnly = Boolean(block.options?.showOnly);

  const { data, error, isLoading, dataUpdatedAt } = useQuery<
    BalanceOverViewState,
    FetchMediaError
  >(
    [authorization, 'app', 'balance'],
    () => fetchBalance(endpoint, authorization!),
    {
      notifyOnChangeProps: ['data', 'error', 'isLoading', 'dataUpdatedAt'],
      enabled: Boolean(endpoint) && Boolean(authorization) && isFocused,
    }
  );

  const { gotoPayment } = useBlockNavigation();
  const { roundness } = useTheme();

  if (!data && error) {
    return (
      <Card>
        <Card.Content>
          <HelperText type="error">
            Can't show your balance right now: {error.message}
          </HelperText>
        </Card.Content>
      </Card>
    );
  }

  const balance = data?.ok ? data.value : null;
  const { display, symbol } = useCurrencyDisplay(balance);

  return (
    <Surface
      style={{ borderRadius: roundness, overflow: 'hidden', elevation: 1 }}
    >
      <List.Item
        style={{ borderRadius: roundness, overflow: 'hidden' }}
        onPress={showOnly ? undefined : gotoPayment}
        left={() =>
          isLoading ? (
            <ActivityIndicator />
          ) : (
            <List.Icon icon="account-cash-outline" />
          )
        }
        title={`${symbol} ${display}`}
        description={`Your balance ${
          dataUpdatedAt
            ? `(at ${new Date(dataUpdatedAt).toLocaleString()})`
            : ''
        }`}
        right={showOnly ? undefined : () => <List.Icon icon="chevron-right" />}
      />
    </Surface>
  );
}
